'use client';

import { useEffect } from 'react';

import Error from 'next/error';

import AppFooter from '@/components/atoms/AppFooter';
import AppSection from '@/components/atoms/AppSection';
import AppHeader from '@/components/organisms/AppHeader';
import { Button } from '@/components/ui/button';
import { reportError } from 'lib/reportError';
import { DEFAULT_LANG } from 'utils/localisation';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    reportError(error);
  }, [error]);

  const locale = DEFAULT_LANG;

  return (
    <html lang={locale}>
      <head></head>
      <body>
        <AppHeader hideSearch={true} landingPage={true} />
        <main className="min-h-40 mt-40">
          <AppSection title="error" className="grid place-items-center min-w-12">
            <div>
              <h1>An error occurred!</h1>
              <p>Something went wrong and we have been notified.</p>
              <Button type="button" onClick={() => reset()} id="button-try-again">
                Reload the page
              </Button>
            </div>
          </AppSection>
        </main>
        <AppFooter />
      </body>
    </html>
  );
}
